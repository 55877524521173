<template lang="pug">
  .turnAssignerArea
      .container-logo
        img.stylelogo.d-block(:src="Logo" v-openlog)
        transition(name="fade" mode="out-in")
          h5(
            v-if="!turn"
            style="position: absolute; margin-top: 30vh; text-align: center; color: firebrick;"
          ) Cargando...
          h5(
            v-else
            style="position: absolute; margin-top: 30vh; text-align: center; color: firebrick;"
          ) Turno cargado. Redireccionando.
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoColmedica from "@/assets/colmedica_logo.png";
import LS from "@/helpers/localStorage";

export default {
  name: "TurnAssignator",

  data: () => ({
    turn: null,
    LogoColmedica
  }),

  created() {
    this.startupLocal();
  },

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      verify_client_data: "verify_client_data",
      getTurnCoorelation: "getTurnCoorelation",
      getTurnInfo: "getTurnInfo",
      putLog: "log/put",
      startCheck: "recording/startCheck",
      sendTurn: "recording/sendTurn"
    }),

    async startupLocal() {
      LS.setItem("user_type", "agent");
      if (this.$route.query.pr) {
        try {
          let { pr } = this.$route.query;
          LS.setItem("data_b64", pr);
          LS.setItem("coor_turnId", this.$route.params.turnId);
          this.verify_client_data();
        } catch (error) {
          this.putLog({
            name:
              "TurnAssigner. Error no restrictivo. No es posible encontrar la información de los parámetros.",
            checked: false,
            message: `Query: ${JSON.stringify(this.$route.query)}
            Error: ${error}`
          });
          console.error("Error in asignation of querystring", error);
        }
      }
      if (this.$route.params.turnId) {
        try {
          this.turn = this.$route.params.turnId;
          const { turn_code } = await this.getTurnCoorelation(this.turn);
          // this.startCheck();
          // this.sendTurn(turn_code);
          await this.getTurnInfo(turn_code);
          if (this.$route.query.silver) {
            setTimeout(() => this.$router.push({ path: "/silver/" }), 0);
          } else {
            setTimeout(() => this.$router.push({ path: "/agent/" }), 0);
          }
        } catch (error) {
          this.putLog({
            name:
              "TurnAssigner. Error restrictivo. No es posible encontrar la correlación.",
            checked: false,
            message: error
          });
        }
      } else
        this.putLog({
          name:
            "TurnAssigner. Error restrictivo. No se puede establecer una videollamada sin una correlación.",
          checked: false,
          message: `Parametros: ${JSON.stringify(this.$route.params)}`
        });
    }
  }
};
</script>
